<template>
  <div class="verify">
    <b-card>
      <b-card-body class="text-center">
        <p>Twój email został potwierdzony</p>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {BCard,BCardBody} from "bootstrap-vue";

export default {
  name: 'Verify',
  methods: {
  },
  components: {
    BCard,BCardBody,
  },
};
</script>
